/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center pb--30 pt--60" name={"uvod"} style={{"backgroundColor":"rgba(233,230,223,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":860}} columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--62 pb--25 pt--25" content={"<span style=\"color: var(--black);\">A jak to celé vzniklo...</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1360}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" content={"22.2.2022 v 11 hodin v 11 minut a 11 vteřin, tehdy to celé začalo a získali jsme toto krásné místo, naší stodolu. Ještě jsme netušili, co přesně se stodolou a stavením uděláme, ale věděli jsme, že chceme, aby toto kouzelné místo opět ožilo.<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--60 pt--60" name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Adresa<br>"}>
              </Title>

              <Text className="text-box" content={"Hudlice, okres Beroun"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--43 swpf--uppercase ls--0 lh--1" content={"Hudlická stodola<br>"}>
              </Title>

              <Button className="btn-box fs--16 swpf--uppercase" use={"page"} href={"/#kontakt"} content={"kontaktujte nás"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box" content={"+420 737 282 756\n<br>info@hudlickastodola.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}